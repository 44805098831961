@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, #root>div {
    height: 100%
  }

#card {
    /*
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    */

    min-height: 25rem;
    min-width: 45rem;

    /*
    background-image: url("./images/so-white.png");
    background-repeat: repeat;
    */
    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

@media screen and (max-width: 768px) {
    #card {
        /*
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        */
    
        min-height: 15rem;
        min-width: 25rem;
    
        /*
        background-image: url("./images/so-white.png");
        background-repeat: repeat;
        */
        background-color: white;
    
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    }
}


@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}